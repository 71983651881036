import React from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Link } from "react-router-dom";
import { Box, Divider, Tabs } from "@mui/material";
import { usePageHeader } from "../../MasterPage/MasterPage";
import { fetchCamara } from "../../hooks/useFetchCamara";
import StakeholderProfileCard from "./StakeholderProfileCard";
import TabPanel from "./StakeholderProfileTabPanel";
import Tab from "./StakeholderProfileTab";
import StakeholderProfileInformacoes from "./Informacoes/StakeholderProfileInformacoes";
import StakeholderProfileConexoesPoliticas from "./ConexoesPoliticas/StakeholderProfileConexoesPoliticas";
import StakeholderProfileAtuacaoLegislativa from "./AtuacaoLegislativa/StakeholderProfileAtuacaoLegislativa";
import StakeholderProfilePosicionamentos from "./Posicionamentos/StakeholderProfilePosicionamentos";
import StakeholdersProfileMapadeInfluencia from "./MapaDeInfluencia/StakeholdersProfileMapadeInfluencia";
import StakeholdersProfileNoticias from "./Noticias/StakeholdersProfileNoticias";
import { formatReferenciasDeputados } from "../utils";
import { StakeholderProfileContext } from "./StakeHolderProfile";
import * as moment from "moment";
import "./stakeholderProfile.css";
import { AppContext } from "../../App";
import axios from "axios";
import Modal from "@mui/material/Modal";
import StakeholderDeceased from "./StakeholderDeceased";
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div>
          <h2>Atualmente os dados não estão disponíveis.</h2>
          <details style={{ whiteSpace: "pre-wrap" }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

const INFORMACOES_ENDPOINTS = {
  frentes: "frentes",
  ocupacoes: "ocupacoes",
  orgaos: "orgaos",
  profissoes: "profissoes",
};

export default function StakeHolderDeputadoProfile({ params }) {
  usePageHeader("Stakeholders - Pessoas (Detalhes)");
  const [referencias, setReferencias] = React.useState(null);
  const StakeholderId = React.useRef(params.id);
  const [stakeholder, setStakeholder] = React.useState(null);
  const [informacoes, setInformacoes] = React.useState(null);
  const [atuacaoLegislativa, setAtuacaoLegislativa] = React.useState(null);
  const [conexoesPoliticas, setConexoesPoliticas] = React.useState(null);
  const [posicionamentos, setPosicionamentos] = React.useState(null);
  const [noticias, setNoticias] = React.useState(null);
  const [stakeholderConnection, setStakeholderConnection] = React.useState([]);
  const [showModal, setShowModal] = React.useState(false);
  const [modalContent, setModalContent] = React.useState(null);
  // const { data } = useFetchCamara("legislaturas");
  const { isMobile, currIdToken, expiresOn, renewSilentToken } =
    React.useContext(AppContext);
  const closeModal = () => {
    setShowModal(false);
  };
  const openModal = () => {
    setModalContent(<StakeholderDeceased close={closeModal} />);
    setShowModal(true);
  };

  const sizeStyle = isMobile
    ? { width: "70vw", height: "32vh" }
    : { width: "40vw", height: "35vh" };

  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    pr: 2,
    borderRadius: "10px",
    display: "flex",
  };

  const fetchInformacoes = (mainEndpoint) => {
    const infoEndpointKeys = Object.keys(INFORMACOES_ENDPOINTS);
    const informacoesPromisses = infoEndpointKeys.map((key) =>
      key == "orgaos"
        ? fetchCamara(`${mainEndpoint}/${key}?ordem=desc&ordenarPor=dataInicio`)
        : fetchCamara(`${mainEndpoint}/${key}`)
    );
    return Promise.all(informacoesPromisses).then((infoResponses) => {
      return infoResponses.reduce((acc, item, index) => {
        return { ...acc, [infoEndpointKeys[index]]: item };
      }, {});
    });
  };

  const getStakeholderConnections = async () => {
    if (expiresOn < new Date()) {
      await renewSilentToken();
    }
    const DeputadoId = StakeholderId.current;
    await axios
      .get(`${process.env.REACT_APP_API_KEY}/moi/get-connection/` + DeputadoId, {
        headers: { Authorization: "Bearer " + currIdToken },
      })
      .then((response) => {
        if (response.data != "") {
          var connectionData = [];
          var getData = response.data;
          for (var i = 0; getData.length > i; i++) {
            connectionData.push({
              id_x: getData[i].stakeholder_id_x,
              id_y: getData[i].stakeholder_id_y,
              nome_x: getData[i].nome_x,
              nome_y: getData[i].nome_y,
              number_of_connections: getData[i].number_of_connections,
              location_connection: getData[i].location_connection,
              legislativa_connection_count: getData[i].legislativa_connection,
              political_party_connection: getData[i].political_party_connection,
              frentes_connection_count: getData[i].frentes_connection,
              orgaos_connection_count: getData[i].orgaos_connection,
              frentes_connection_count_total: getData[i].count_of_frentes,
              orgaos_connection_count_total: getData[i].count_of_orgaos,
              legislativa_connection_count_total: getData[i].count_of_legislativa,
              location_x: {
                lat: parseFloat(getData[i].latitude_x),
                lng: parseFloat(getData[i].longitude_x),
              },
              location_y: {
                lat: parseFloat(getData[i].latitude_y),
                lng: parseFloat(getData[i].longitude_y),
              },
              siglaPartido_x: getData[i].siglaPartido_x,
              siglaPartido_y: getData[i].siglaPartido_y,
              state_x: getData[i].state_x,
              state_y: getData[i].state_y,
            });
          }
          setStakeholderConnection(connectionData);
        }
      })
      .catch(renewSilentToken);
  };

  const fetchAtuacaoLegislativa = (DeputadoId) => {
    const promises = [
      fetchCamara(`proposicoes`, { ano: 2024, idDeputadoAutor: DeputadoId }),
      fetchCamara("referencias/proposicoes"),
    ];
    return Promise.all(promises).then(([proposicoes, referenciasProposicoes]) => ({
      proposicoes,
      referenciasProposicoes,
    }));
  };

  const fetchConexoesPoliticas = ({ ultimoStatus: deputado }, informacoes) => {
    const promiseLegislaturas = fetchCamara(
      `legislaturas/${deputado.idLegislatura}/mesa`
    );
    const promiseFrentes = Promise.all(
      informacoes.frentes?.slice(0, 10).map((frente, i) => {
        return fetchCamara(`frentes/${frente.id}/membros`);
      })
    );
    const FrentesID = Promise.all(
      informacoes.frentes?.slice(0, 10).map((frente, i) => {
        return fetchCamara(`frentes/${frente.id}`);
      })
    );

    const promiseOrgaos = Promise.all(
      informacoes.orgaos?.slice(0, 10).map((orgao) => {
        return fetchCamara(`orgaos/${orgao.idOrgao}/membros`);
      })
    );

    return Promise.all([
      promiseLegislaturas,
      promiseFrentes,
      promiseOrgaos,
      FrentesID,
    ]).then(([legislaturas, frentes, orgaos, FrentesID]) => ({
      legislaturas,
      frentes,
      orgaos,
      FrentesID,
    }));
  };

  const today = new Date(); // get current date
  const FormattedCurrentDate = moment(today).format("YYYY-MM-DD");
  const last = new Date(today.setDate(today.getDate() - today.getDay() - 90)); // today - 90
  const FormattedLastDate = moment(last).format("YYYY-MM-DD");
  const fetchPosicionamentos = (mainEndpoint) =>
    fetchCamara(
      `${mainEndpoint}/discursos?dataInicio=${FormattedLastDate}&dataFim=${FormattedCurrentDate}&ordenarPor=dataHoraInicio&ordem=DESC&itens=100`
    );
  React.useEffect(() => {
    if (!currIdToken) return;
    getStakeholderConnections();
  }, [currIdToken]);
  React.useEffect(() => {
    const DeputadoId = StakeholderId.current;
    const mainEndpoint = `deputados/${DeputadoId}`;

    fetchCamara(`referencias/deputados`).then((r) =>
      setReferencias(formatReferenciasDeputados(r))
    );
    fetchAtuacaoLegislativa(DeputadoId).then(setAtuacaoLegislativa);
    fetchPosicionamentos(mainEndpoint).then(setPosicionamentos);
    fetchCamara(mainEndpoint).then((r) => setNoticias(r.ultimoStatus.nome));

    const deputadoPromise = fetchCamara(mainEndpoint).then(async (r) => {
      setStakeholder(r);
      return r;
    });
    const informacoesPromise = fetchInformacoes(mainEndpoint).then((r) => {
      setInformacoes(r);
      return r;
    });
    Promise.all([deputadoPromise, informacoesPromise]).then(([deputado, informacao]) => {
      fetchConexoesPoliticas(deputado, informacao).then(setConexoesPoliticas);
    });
  }, []);

  const [tabIndex, setTabIndex] = React.useState(0);
  const handleChangeTab = (event, newValue) => setTabIndex(newValue);
  const mobileClass = isMobile ? "mobile" : "";

  return (
    <StakeholderProfileContext.Provider value={{ referencias }}>
      <Box>
        <Link to="/stakeholders-pessoas" className={`back ${mobileClass}`}>
          <KeyboardBackspaceIcon /> Voltar
        </Link>
        <StakeholderProfileCard
          deputado
          stakeholder={stakeholder}
          pdfContent={{
            stakeholder,
            informacoes,
            atuacaoLegislativa,
            conexoesPoliticas,
            posicionamentos,
          }}
        />
      </Box>
      <Tabs
        value={tabIndex}
        onChange={handleChangeTab}
        textColor="primary"
        indicatorColor="primary"
      >
        <Tab label="Informações" index={0} />
        <Tab label="Atuação Legislativa" index={1} />
        <Tab label="Conexões Políticas" index={2} />
        <Tab label="Posicionamentos" index={3} />
        <Tab label="Notícias" index={4} />
        <Tab label="Mapa De Conexão" index={5} />
      </Tabs>
      <TabPanel value={tabIndex} index={0}>
        <ErrorBoundary>
          <StakeholderProfileInformacoes informacoes={informacoes} />
        </ErrorBoundary>
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <ErrorBoundary>
          <StakeholderProfileAtuacaoLegislativa atuacaoLegislativa={atuacaoLegislativa} />
        </ErrorBoundary>
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <ErrorBoundary>
          <StakeholderProfileConexoesPoliticas
            conexoesPoliticas={conexoesPoliticas}
            StakeholderId={params.id}
            informacoes={informacoes}
          />
        </ErrorBoundary>
      </TabPanel>
      <TabPanel value={tabIndex} index={3}>
        <ErrorBoundary>
          <StakeholderProfilePosicionamentos
            posicionamentos={posicionamentos}
            nome={noticias}
          />
        </ErrorBoundary>
      </TabPanel>
      <TabPanel value={tabIndex} index={4}>
        <ErrorBoundary>
          <StakeholdersProfileNoticias noticias={noticias} />
        </ErrorBoundary>
      </TabPanel>
      <TabPanel value={tabIndex} index={5}>
        <ErrorBoundary>
          <StakeholdersProfileMapadeInfluencia
            stakeholderConnection={stakeholderConnection}
          />
        </ErrorBoundary>
      </TabPanel>
      <Modal open={showModal} onClose={closeModal}>
        <Box sx={{ ...styleModal, ...sizeStyle }}>
          <div
            style={{
              width: "100%",
              // overflowY: "scroll",
              // overflowX: "hidden",
              paddingRight: "16px",
            }}
          >
            {modalContent}
          </div>
        </Box>
      </Modal>
      ;
    </StakeholderProfileContext.Provider>
  );
}
